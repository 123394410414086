<template>
  <section>
    <div class="payment">
      <div class="svg-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="82"
          height="82"
        >
          <path
            d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
          />
        </svg>
      </div>
      <div class="confirmation">
        <h4>Confirmation Page</h4>
        <div class="content-payement">
          <strong
            ><p>
              We have received your payment. You now have access to your
              credits, if you don't see them,log out and log back in.
            </p></strong
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { analyticsService } from "../../../common/services/analytics.service";
export default {
  name: "payementconfirmation",
  data() {
    return {
      user_detail: {},
    };
  },
  methods: {
    async payementAnalyticService() {
      if (
        localStorage.getItem("user_email") &&
        localStorage.getItem("user_email") !== null &&
        localStorage.getItem("total_Price") &&
        localStorage.getItem("total_Price") !== null &&
        localStorage.getItem("billing_Type") &&
        localStorage.getItem("billing_Type") !== null
      ) {
        let successobj = {
          email: localStorage.getItem("user_email"),
          value: localStorage.getItem("total_Price"),
          period: localStorage.getItem("billing_Type"),
          currency: "USD",
          context: {
            // groupId: localStorage.getItem("user_email").split("@")[1],
            groupId: (localStorage.getItem("user_email") || "").split("@")[1],
          },
        };
        analyticsService.userPayementSuccess( this.user_detail,successobj);
        localStorage.removeItem("total_Price");
        localStorage.removeItem("billing_Type");
      }
    },
  },

  beforeMount() {
    this.user_detail = this.$store.getters.getstoreUser;
    this.$eventBus.$emit("callCreditMethod");
  },

  mounted() {
    this.payementAnalyticService();
  },

  computed: {},
};
</script>
<style>
.main-content section {
  min-height: 800px;
  overflow: auto;
}
.payment {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 500px;
  margin: auto;
}
.confirmation {
  display: flex;
  flex-direction: column;
  gap: 22px;
  justify-content: end;
  text-align: center;
  background: rgb(247 248 250);
  padding: 40px 60px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
.svg-icon {
  text-align: center;
  background: #2589ff;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
.svg-icon svg {
  fill: #fff;
}
</style>
